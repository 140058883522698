var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "timeslot-left-dialog",
      attrs: {
        width: "40%",
        title: "Подтверждение:",
        visible: _vm.dialogVisible,
        "append-to-body": "",
        "before-close": _vm.close,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "span",
        [
          _c("div", { staticClass: "confirm-delete-item" }, [
            _c("b", [_vm._v("Терминал:")]),
            _c("span", { staticStyle: { color: "#4ca7d8" } }, [
              _c("b", [
                _vm._v(
                  " " + _vm._s(_vm.dialogDataSlots[0].timeslot["unload_name"])
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "confirm-delete-item" }, [
            _c("b", [_vm._v("Культура:")]),
            _c("span", { staticStyle: { color: "#4ca7d8" } }, [
              _c("b", [
                _vm._v(
                  " " + _vm._s(_vm.dialogDataSlots[0].timeslot["culture_name"])
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "confirm-delete-item" }, [
            _c("b", [_vm._v("Тип авто:")]),
            _c("span", { staticStyle: { color: "#4ca7d8" } }, [
              _c("b", [_vm._v(" " + _vm._s(_vm.auto))]),
            ]),
          ]),
          _vm.dialogDataSlots &&
          _vm.dialogDataSlots.length > 0 &&
          _vm.dialogDataSlots[0].timeslot["exporter_name"]
            ? _c("div", { staticClass: "confirm-delete-item" }, [
                _c("b", [_vm._v("Поставщик:")]),
                _c("span", { staticStyle: { color: "#4ca7d8" } }, [
                  _c("b", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.dialogDataSlots[0].timeslot["exporter_name"])
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm._l(_vm.dialogData, function (item) {
            return [
              _c(
                "div",
                { key: item.timeslot_id, staticClass: "confirm-item" },
                [
                  _c("div", { staticClass: "confirm-item-time" }, [
                    _c("span", { staticClass: "confirm-item-title" }, [
                      _vm._v("Номер авто:"),
                    ]),
                    _c("div", { staticClass: "confirm-item-body" }, [
                      _c("i", {
                        staticClass: "el-icon-truck icon-confirm show-icon",
                      }),
                      _c("span", [_vm._v(" " + _vm._s(item["plate_truck"]))]),
                    ]),
                  ]),
                  _c("div", { staticClass: "confirm-item-time" }, [
                    _c("div", { staticClass: "confirm-item-title" }, [
                      _vm._v("Дата и время:"),
                    ]),
                    _c("div", { staticClass: "confirm-item-body" }, [
                      _c("i", {
                        staticClass:
                          "el-icon-alarm-clock icon-confirm show-icon",
                      }),
                      _c("span", [
                        _vm._v(" " + _vm._s(_vm.dataFromDateTo(item)) + " "),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ]
          }),
        ],
        2
      ),
      _c(
        "span",
        [
          _c("el-checkbox", {
            staticClass: "confirm_checkbox",
            attrs: {
              label: "Я подтверждаю бронирование таймслот(ов) ",
              border: "",
              fill: "#4CA7D8",
              size: "large",
            },
            model: {
              value: _vm.confirmGetSlots,
              callback: function ($$v) {
                _vm.confirmGetSlots = $$v
              },
              expression: "confirmGetSlots",
            },
          }),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer-second",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "iq-button",
            {
              attrs: { "full-width": "", color: "second", size: "small" },
              on: { onClick: _vm.close },
            },
            [_vm._v(" Отменить ")]
          ),
          _vm.confirmGetSlots
            ? _c(
                "iq-button",
                {
                  attrs: { disabled: !_vm.confirmGetSlots, size: "small" },
                  on: {
                    onClick: function ($event) {
                      return _vm.submitForm("billForm")
                    },
                  },
                },
                [_vm._v(" Подтвердить ")]
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }