<template>
  <el-dialog
    class="timeslot-left-dialog"
    width="40%"
    title="Подтверждение:"
    :visible.sync="dialogVisible"
    append-to-body
    :before-close="close"
  >
    <span>
      <div class="confirm-delete-item">
        <b>Терминал:</b>
        <span style="color: #4ca7d8">
          <b> {{ dialogDataSlots[0].timeslot['unload_name'] }}</b>
        </span>
      </div>
      <div class="confirm-delete-item">
        <b>Культура:</b>
        <span style="color: #4ca7d8">
          <b> {{ dialogDataSlots[0].timeslot['culture_name'] }}</b>
        </span>
      </div>
      <div class="confirm-delete-item">
        <b>Тип авто:</b>
        <span style="color: #4ca7d8">
          <b> {{ auto }}</b>
        </span>
      </div>
      <div
        v-if="
          dialogDataSlots &&
          dialogDataSlots.length > 0 &&
          dialogDataSlots[0].timeslot['exporter_name']
        "
        class="confirm-delete-item"
      >
        <b>Поставщик:</b>
        <span style="color: #4ca7d8">
          <b> {{ dialogDataSlots[0].timeslot['exporter_name'] }}</b>
        </span>
      </div>
      <template v-for="item in dialogData">
        <div :key="item.timeslot_id" class="confirm-item">
          <div class="confirm-item-time">
            <span class="confirm-item-title">Номер авто:</span>
            <div class="confirm-item-body">
              <i class="el-icon-truck icon-confirm show-icon" />
              <span> {{ item['plate_truck'] }}</span>
            </div>
          </div>
          <div class="confirm-item-time">
            <div class="confirm-item-title">Дата и время:</div>
            <div class="confirm-item-body">
              <i class="el-icon-alarm-clock icon-confirm show-icon" />
              <span>
                {{ dataFromDateTo(item) }}
              </span>
            </div>
          </div>
        </div>
      </template>
    </span>
    <span>
      <el-checkbox
        v-model="confirmGetSlots"
        class="confirm_checkbox"
        label="Я подтверждаю бронирование таймслот(ов) "
        border
        fill="#4CA7D8"
        size="large"
      />
    </span>
    <span slot="footer" class="dialog-footer-second">
      <iq-button full-width color="second" size="small" @onClick="close">
        Отменить
      </iq-button>
      <iq-button
        v-if="confirmGetSlots"
        :disabled="!confirmGetSlots"
        size="small"
        @onClick="submitForm('billForm')"
      >
        Подтвердить
      </iq-button>
    </span>
  </el-dialog>
</template>

<script>
import { DATE_FORMAT_RU, GET_TIME } from '@/constants/date'
import { autoType } from '@/constants/auto-type'
import { getFormattedTzDate } from '@/core'
import IqButton from '@/views/ui/components/buttons/IqButton'

export default {
  components: { IqButton },
  props: {
    dialogVisible: {
      type: Boolean,
      required: true,
    },
    dialogData: {
      type: Array,
      required: true,
    },
    dialogDataSlots: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      confirmGetSlots: false,
      autoType,
    }
  },
  computed: {
    auto() {
      // eslint-disable-next-line no-nested-ternary
      return this.dialogDataSlots
        ? this.dialogDataSlots[0].timeslot['truck_type_code'] === 'boardSider'
          ? 'Бортовой'
          : 'Самосвал'
        : null
    },
  },
  methods: {
    dataFromDateTo(item) {
      let slot = this.dialogDataSlots.find(
        i => i.timeslot_id === item.timeslot_id,
      )
      let timeFrom = getFormattedTzDate(slot.timeslot['window_from'], GET_TIME)
      let timeTo = getFormattedTzDate(slot.timeslot['window_to'], GET_TIME)
      let day = getFormattedTzDate(slot.timeslot['window_from'], DATE_FORMAT_RU)

      return `${timeFrom} - ${timeTo}  ${day}`
    },
    submitForm() {
      if (!this.confirmGetSlots) {
        return
      }
      this.$emit('close', 1)
    },

    close() {
      this.$emit('close', 0)
    },
  },
}
</script>

<style lang="sass">
$bg-modal: rgba(225, 225, 225, 0.82)
$bg-modal-header: #4CA7D8
$white: #fff
$mainFont: #606266
.confirm_checkbox
  width: 100%
  .el-checkbox__label
    font-size: 14px
    font-weight: bold
    height: 56px
    width: 100%
.confirm-item-title
  display: inline-block
  font-weight: bold
  margin-bottom: 12px
  margin-right: 4px
  color: #999!important
  @media(max-width: 500px)
    margin-bottom: 0px

.confirm-item-body
  color: #4ca7d8
  display: flex
  align-items: center
  margin-bottom: 12px
  @media(max-width: 500px)
    font-weight: bold!important
    margin-bottom: 0px
.confirm-item
  justify-content: space-between
  display: flex
  border: 1px solid $bg-modal
  border-radius: 4px
  padding: 12px
  margin-bottom: 8px
  @media(max-width: 500px)
    width: 100%
    flex-direction: column
.show-icon
  @media(max-width: 500px)
    display: none
.confirm-item-time
  @media(max-width: 500px)
    display: flex
    align-items: center
.icon-confirm
  display: inline-block
  font-size: 24px!important
  color: $mainFont
  @media(max-width: 500px)
    display: none!important
    font-size: 24px!important
    margin-right: 4px
.confirm-delete-item
  margin-bottom: 8px
.timeslot-left-dialog
  background: $bg-modal

  .el-dialog
    border-radius: 4px!important
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1) !important
    @media(max-width: 1120px)
      width: 50%!important
    @media(max-width: 768px)
      width: 80%!important
    @media(max-width: 480px)
      width: 90%!important
  .el-dialog__body
    padding: 12px 15px!important
  .dialog-footer-second
    display: flex
    flex-wrap: nowrap
    align-items: center
    justify-content: space-between
    @media(max-width: 420px)
      flex-wrap: wrap
  .el-button
    @media(max-width: 420px)
      margin-left: 0px!important
      margin-bottom: 10px!important
      margin-top: 10px!important
  .el-dialog__headerbtn
    top: 13px!important
    right: 10px!important
  .el-dialog__close
    font-size: 24px!important
    color: $white!important
  .el-dialog__header
    background: $bg-modal-header!important
    padding: 12px 15px!important
    border-radius: 4px 4px 0px 0px!important
    > span
      font-style: normal!important
      font-weight: 500!important
      font-size: 18px!important
      line-height: 26px!important
      color: $white!important
</style>
